import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ScrWrapper } from 'components';
import DraggablePaper from 'components/Draggable/DraggablePaper';
import { LoadingTextComponent } from 'components/LoadingComponent';
import { DeltakerInformation } from 'features/deltaker/components';
import { fetchHenvendelseKanalIdsByHenvendelseType } from 'features/deltaker/state/henvendelse/kanaler/thunks';
import { DialogTypesEnum } from 'features/deltaker/state/infoDemaskert/interface';
import { setTypeToDemask } from 'features/deltaker/state/infoDemaskert/slice';
import { demaskDeltaker } from 'features/deltaker/state/infoDemaskert/thunks';
import { useSysProperties } from 'features/sys';
import { FC, useCallback, useEffect, useState } from 'react';
import { DeltakerLogDto } from 'services/deltaker/dto';
import {
  GetHenvendelseDto,
  HenvendelseTypeDto,
} from 'services/henvendelser/dto';
import { getHenvendelse } from 'services/henvendelser/henvendelerService';
import { formatDateTimeFromISO } from 'utils/date';
import { useFetchData } from 'utils/hooks';
import { defaultDialogWidth } from 'utils/styles';

import DataRow from '../../../DeltakerCommunicationLog/DataRow';
import DemaskerButton from '../../../DemaskerButton';
import BaseDialog from '../BaseDialog';
import { showHenvendelseSelector } from './selector';

type Props = {
  henvendelseTypeId?: number;
  close: () => void;
  log: DeltakerLogDto;
};

const ShowHenvendelse: FC<Props> = ({ henvendelseTypeId, close, log }) => {
  const dispatch = useAppDispatch();

  const sysHenvendelseTyper = useSysProperties('henvendelseTyper');
  const sysHenvendelseKanaler = useSysProperties('henvendelseKanaler');
  const [openHenvendelserDialog, setOpenHenvendelserDialog] = useState(false);

  const { isDemaskert, deltakerHasBeenDemaskert, deltakerId } = useAppSelector(
    showHenvendelseSelector
  );

  const fetcher = useCallback(
    () => getHenvendelse(log.primærnøkkelId as number),
    [log.primærnøkkelId]
  );
  const { status, data } = useFetchData<GetHenvendelseDto>(fetcher);
  useEffect(() => {
    if (henvendelseTypeId) {
      dispatch(fetchHenvendelseKanalIdsByHenvendelseType(henvendelseTypeId));
    }
  }, [dispatch, henvendelseTypeId]);

  const sysHenvendelseKategorier = useSysProperties('henvendelseKategorier');
  const isAllLoaded =
    sysHenvendelseTyper.isLoaded &&
    sysHenvendelseKanaler.isLoaded &&
    sysHenvendelseKategorier.isLoaded &&
    status.loadingComplete;

  if (!isAllLoaded) return null;

  const toggleOpenHenvendelseDialog = () => {
    dispatch(
      setTypeToDemask({ typeToOpenOnSuccess: DialogTypesEnum.HENVENDELSE })
    );
    if (!deltakerHasBeenDemaskert) {
      deltakerId && dispatch(demaskDeltaker({ deltakerId }));
    }
    setOpenHenvendelserDialog((prevState) => !prevState);
  };

  const henvendelseType = sysHenvendelseTyper.map(
    henvendelseTypeId
  ) as HenvendelseTypeDto;
  return (<>
    <Dialog
      open={true}
      onClose={close}
      aria-labelledby="deltaker-communication-dialog"
      PaperComponent={(props) => (
        <DraggablePaper
          paperProps={props}
          handle="#deltaker-communication-dialog"
          cancel='[class*="MuiDialogContent-root"]'
        />
      )}
      slotProps={{ paper: { sx: defaultDialogWidth } }}>
      <DialogTitle
        style={{ cursor: 'move' }}
        id="deltaker-communication-dialog">
        <LoadingTextComponent
          height={32}
          text={henvendelseType.beskrivelse}
          isLoading={false}
        />
      </DialogTitle>
      <DialogContent>
        <DemaskerButton fullwidth={true} />
        <ScrWrapper variant="dialog" sx={{ mt: 1 }}>
          <DeltakerInformation />
        </ScrWrapper>
        <ScrWrapper variant="dialog" sx={{ mt: 1 }}>
          <DataRow
            left={4}
            right={8}
            title="Tidspunkt mottatt"
            value={formatDateTimeFromISO(data?.mottattTidspunkt, false)}
          />
        </ScrWrapper>
        <ScrWrapper variant="dialog" sx={{ mt: 1 }}>
          <DataRow
            left={4}
            right={8}
            title="Kanal"
            value={sysHenvendelseKanaler.mapBeskrivelse(
              data?.henvendelseKanalId
            )}
          />
        </ScrWrapper>
        {data?.henvendelseKategoriIder?.length && (
          <ScrWrapper variant="dialog" sx={{ mt: 1 }}>
            <DataRow
              left={4}
              right={8}
              title="Kategorisering"
              value={data.henvendelseKategoriIder
                ?.map((kategori, i) =>
                  sysHenvendelseKategorier.mapBeskrivelse(kategori)
                )
                .join(', ')}
            />
          </ScrWrapper>
        )}
        {data?.harTekst && (
          <ScrWrapper variant="dialog" sx={{ mt: 1 }}>
            <Typography variant="subtitle2">Kommentar:</Typography>
            <Typography variant="body2">
              {isDemaskert ? data?.tekst : '****** **** ******* *****'}
            </Typography>
          </ScrWrapper>
        )}

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <ScrWrapper variant="dialog">
            <Typography variant="body2">{log.hovertekst}</Typography>
          </ScrWrapper>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 3 }}>
        {data?.henvendelseKanalId !== 1 &&
          data?.henvendelseKanalId !== 2 && ( // SMS og helsenorge.no -> Hentes fra sys-tabell?
            (<Button variant="contained" onClick={toggleOpenHenvendelseDialog}>rediger henvendelse
                            </Button>)
          )}
        <Button variant="contained" onClick={close} color="secondary">
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
    {openHenvendelserDialog && deltakerHasBeenDemaskert && (
      <BaseDialog
        open={true}
        henvendelseType="OPPDATER"
        henvendelseTypeId={henvendelseTypeId}
        log={log}
        close={() => setOpenHenvendelserDialog(false)}
      />
    )}
  </>);
};

export default ShowHenvendelse;
