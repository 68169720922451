import { Box, Divider, FormControl, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingInputComponent } from 'components';
import {
  getOppfølgingById,
  initializeForm,
  sendOppfølging,
} from 'features/deltaker/state/oppfølgingspunkt/thunks';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  deleteUrlSearchParam,
  getUrlSearchParam,
  hasUrlSearchParamWithValue,
} from 'utils/commonHelperFunctions';

import { DialogContentWrapper, DialogFormButtons } from '../components';
import { IndividualOppfølgingspunktFormProps } from './interface';
import { individualOppfølgingspunkt } from './selectors';
import { OppfølgingspunktFormFields } from '.';

const IndividualOppfølgingspunktForm: React.FC<
  IndividualOppfølgingspunktFormProps
> = ({ close }) => {
  const dispatch = useAppDispatch();

  const {
    status,
    closeForm,
    isDoingSomeFetching,
    eksternTekst,
    registrertEndret,
    eksternTekstKreverDemaskering,
    isDemaskert,
    brukernavnStatus,
    inDialogErrorMessage,
    deltakerId,
    formIsEmpty,
    fristHasAlreadyBeenSetOnce,
    formValues,
  } = useAppSelector(individualOppfølgingspunkt);

  const isExistingOppfølgingspunkt = hasUrlSearchParamWithValue('oppfolgingId');
  const oppfølgingId = getUrlSearchParam('oppfolgingId');

  const navigate = useNavigate();

  useEffect(() => {
    if (eksternTekstKreverDemaskering && isDemaskert && !eksternTekst) {
      dispatch(getOppfølgingById(Number(oppfølgingId)));
    }
  }, [
    eksternTekstKreverDemaskering,
    isDemaskert,
    eksternTekst,
    dispatch,
    oppfølgingId,
  ]);

  useEffect(() => {
    if (!isDoingSomeFetching) {
      if (formIsEmpty && isExistingOppfølgingspunkt) {
        dispatch(getOppfølgingById(Number(oppfølgingId)));
      } else if (fristHasAlreadyBeenSetOnce)
        dispatch(initializeForm('INDIVIDUAL'));
    }
  }, [
    dispatch,
    formIsEmpty,
    fristHasAlreadyBeenSetOnce,
    isDoingSomeFetching,
    isExistingOppfølgingspunkt,
    oppfølgingId,
  ]);

  useEffect(() => {
    if (
      !!formValues.id &&
      !!oppfølgingId &&
      formValues.id !== Number(oppfølgingId)
    ) {
      navigate(0);
    }
  }, [oppfølgingId, navigate, formValues.id]);

  const deleteQueryStringAndClose = useCallback(() => {
    deleteUrlSearchParam('oppfolgingId');
    close();
  }, [close]);

  useEffect(() => {
    if (closeForm) deleteQueryStringAndClose();
  }, [close, closeForm, deleteQueryStringAndClose]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(sendOppfølging(oppfølgingId));
  };

  const disableSubmit =
    !deltakerId ||
    (brukernavnStatus.loading && !brukernavnStatus.loadingComplete);

  return (
    <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
      <LoadingInputComponent showLoading={!status.loadingComplete}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <DialogContentWrapper
            showDemasker={eksternTekstKreverDemaskering}
            tekstOverskrift={`${
              oppfølgingId !== null ? 'Rediger' : 'Nytt'
            } oppfølgingspunkt`}
            errorMessage={
              inDialogErrorMessage
                ? { title: 'Noe gikk galt', message: inDialogErrorMessage }
                : undefined
            }>
            <OppfølgingspunktFormFields />
            {oppfølgingId !== null && registrertEndret && (
              <>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                  <Typography variant="body2">{registrertEndret}</Typography>
                </Box>
              </>
            )}
          </DialogContentWrapper>
          <DialogFormButtons
            tekstHandling="Lagre"
            close={deleteQueryStringAndClose}
            isLoading={status.loading}
            disabled={disableSubmit}
          />
        </form>
      </LoadingInputComponent>
    </FormControl>
  );
};

export default IndividualOppfølgingspunktForm;
