import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { removeBrukerId } from 'features/deltaker/state/oppfølgingspunkt/slice';
import { setBrukerIdFromNavn } from 'features/deltaker/state/oppfølgingspunkt/thunks';
import { useEffect, useState } from 'react';

import { OppfølgingspunktUsernameProps } from './interface';
import { loggedInBruker, oppfølgingspunkt } from './selectors';

const OppfølgingspunktUsername: React.FC<OppfølgingspunktUsernameProps> = ({
  onInvalid,
}) => {
  const dispatch = useAppDispatch();

  const {
    brukernavn: { brukernavn, status },
  } = useAppSelector(oppfølgingspunkt);

  const { userName } = useAppSelector(loggedInBruker);

  const [fieldBrukernavn, setFieldBrukernavn] = useState(() => {
    if (brukernavn !== '') return brukernavn;
    return userName ?? '';
  });

  useEffect(() => {
    const debounceHits = setTimeout(() => {
      dispatch(setBrukerIdFromNavn(fieldBrukernavn));
    }, 500);

    dispatch(removeBrukerId({}));
    return () => {
      clearTimeout(debounceHits);
    };
  }, [fieldBrukernavn, dispatch]);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldBrukernavn(value);
  };

  return (
    (<TextField
      sx={{ mt: 2, mb: 2 }}
      label="Utført av"
      name="utførtAvBrukerId" // id er hva som skal valideres
      id="utførtAvBrukerName"
      required
      onChange={handleChange}
      onInvalid={onInvalid}
      value={fieldBrukernavn}
      error={!!status.errorMessage && !status.loading}
      fullWidth
      helperText={status.errorMessage}
      slotProps={{
        input: {
          startAdornment: status.loading ? (
            <InputAdornment position="start">
              <CircularProgress
                aria-label="laster informasjon om bruker"
                size={10}
              />
            </InputAdornment>
          ) : (
            <></>
          ),
        }
      }}
    />)
  );
};

export default OppfølgingspunktUsername;
