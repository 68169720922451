import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { ScrIcon } from 'components';
import ScrIconProps from 'components/ScrIcon/interface';
import { forwardRef, useMemo, useState } from 'react';
import {
  NavLink,
  LinkProps as RouterLinkProps,
} from 'react-router';

import { Size } from '../interface';

// https://material-ui.com/guides/composition/#list
export type Props = {
  iconId: ScrIconProps['iconId'];
  size: Size;
  primary?: string;
  name: string;
  to: string;
  selected?: boolean;
  onPopoverOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => void;
  onPopoverClose?: VoidFunction;
};

const listItemSx = {
  color: 'primary.main',
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: 'scr.blue50',
    textUnderlinePosition: 'under',
    backgroundColor: 'common.white',
  },
};

const ListItemLink: React.FC<Props> = ({
  iconId,
  size,
  to,
  name,
  selected = false,
  onPopoverOpen,
  onPopoverClose,
}: Props) => {
  const theme = useTheme();

  const { primary, common, scr } = theme.palette;

  const [isMouseOver, setIsMouseOver] = useState(false);

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <ListItem sx={{ p: 0 }}>
          <NavLink to={to} ref={ref} {...itemProps} />
        </ListItem>
      )),
    [to]
  );

  const getBgColor = () => {
    if (isMouseOver) return scr.blue50;
    else if (selected) return primary.main;

    return common.white;
  };

  const listItemIcon = (
    <ListItemIcon sx={{ justifyContent: 'center' }}>
      <ScrIcon
        iconId={iconId}
        size="large"
        borderColor={primary.main}
        color={selected && !isMouseOver ? common.white : primary.main}
        bgcolor={getBgColor()}
      />
    </ListItemIcon>
  );

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    setIsMouseOver(true);

    if (size === Size.Small) onPopoverOpen && onPopoverOpen(event, name);
  };

  const handleMouseLeave = (): void => {
    setIsMouseOver(false);

    if (size === Size.Small) onPopoverClose && onPopoverClose();
  };

  const listItemProps = {
    selected: selected,
    component: renderLink,
    'aria-label': `lenke til ${name}`,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  return size === Size.Small ? (
    <ListItem {...listItemProps} sx={listItemSx}>
      {listItemIcon}
    </ListItem>
  ) : (
    <ListItem
      {...listItemProps}
      sx={{ ...listItemSx, pl: size === Size.Medium ? 1 : 2 }}
      dense>
      {listItemIcon}
      <ListItemText disableTypography sx={{ fontSize: 16 }}>
        {name}
      </ListItemText>
    </ListItem>
  );
};

export default ListItemLink;
