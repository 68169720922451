import { Button, DialogActions } from '@mui/material';
import { FC } from 'react';

import { DialogFormButtonsProps } from './interface';

const DialogFormButtons: FC<DialogFormButtonsProps> = ({
  close,
  isLoading,
  tekstHandling,
  disabled,
}) => {
  return (
    <DialogActions>
      <Button type="button" disabled={isLoading} onClick={close}>
        Avbryt
      </Button>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={disabled}>
        {tekstHandling}
      </Button>
    </DialogActions>
  );
};
export default DialogFormButtons;
