import { Box, Grid2, Typography } from '@mui/material';
import { Heading, ScrWrapper, Status } from 'components';
import React from 'react';

import { CardProps } from './interface';

const padding = { px: 2, py: 1 };

const ScrCard: React.FC<CardProps> = ({
  id,
  title,
  titleVariant,
  subtitle,
  statusProps,
  children,
  hue = {
    backgroundColor: 'scr.grey200',
    borderColor: 'scr.grey200',
    color: 'primary.main',
  },
  sx,
}) => {
  return (
    <ScrWrapper
      sx={{
        borderColor: hue.borderColor,
        ...sx,
      }}>
      <Box component="section" aria-labelledby={id}>
        <Box
          sx={{
            ...padding,
            bgcolor: hue.backgroundColor,
          }}>
          <Grid2 container>
            <Grid2 size={{ xs: statusProps ? 9 : 12 }}>
              <Heading
                id={id}
                componentVariant={titleVariant}
                text={title}
                inline
                noMargin
                xs={{
                  color: hue.color,
                }}
              />
              {subtitle && (
                <Typography
                  variant="body2"
                  sx={{ ml: 2, color: 'primary.main', display: 'inline' }}>
                  {subtitle}
                </Typography>
              )}
            </Grid2>
            {statusProps && (
              <Grid2 container size={{ xs: 3 }} justifyContent="flex-end">
                <Status
                  {...statusProps}
                  textXs={{
                    ...statusProps.textXs,
                    color: hue.color,
                  }}
                />
              </Grid2>
            )}
          </Grid2>
        </Box>
        <Box sx={padding}>{children}</Box>
      </Box>
    </ScrWrapper>
  );
};

export default ScrCard;
