import { Button, FormGroup, Grid2, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { ActionEnum, NotificationAction } from 'features/notification';
import {
  NotificationMessage,
  VariantType,
} from 'features/notification/interface';
import {
  addNotification,
  dismissAllNotifications,
} from 'features/notification/notificationSlice';

const NotificationExample: React.FC = () => {
  const dispatch = useAppDispatch();

  const dispatchNewNotification = (
    message: NotificationMessage,
    variant: VariantType,
    action?: NotificationAction
  ) =>
    dispatch(
      addNotification({
        message,
        variant,
        action,
        timeout: 10000,
      })
    );

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <FormGroup>
          <Button onClick={() => dispatch(dismissAllNotifications())}>
            Fjern alle notifikasjoner
          </Button>
        </FormGroup>
      </Grid>
      <Grid container item xs={12}>
        <Button
          variant="outlined"
          onClick={() =>
            dispatchNewNotification({ text: 'En infobeskjed' }, 'info')
          }>
          Info
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            dispatchNewNotification(
              { title: 'Det var noe som feilet', text: 'En feilmelding' },
              'error',
              {
                clickAction: {
                  action: ActionEnum.Refresh,
                },
                text: 'Last på nytt',
              }
            )
          }>
          Error
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            dispatchNewNotification(
              { title: 'Dette gikk bra', text: 'En suksessbeskjed' },
              'success'
            )
          }>
          Success
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            dispatchNewNotification({ text: 'En advarsel' }, 'warning')
          }>
          Warning
        </Button>
        <Button
          variant="outlined"
          data-cy="notificationWithRedirectButton"
          onClick={() => {
            dispatchNewNotification(
              {
                title: 'Redirect',
                text: 'Trykk på redirect-knappen for å navigere til annen side',
              },
              'info',
              {
                text: 'Gå til Personoppslag',
                clickAction: {
                  action: ActionEnum.Redirect,
                  value: '/personoppslag',
                },
              }
            );
          }}>
          Med redirect
        </Button>
      </Grid>
      <Grid item xs={12}>
        <InfoCounter />
      </Grid>
    </Grid>
  );
};

const InfoCounter: React.FC = () => {
  const notifications = useAppSelector(
    (state: RootState) => state.notification
  );

  return (
    <Typography>
      Antall notifikasjoner i state: {notifications.length}
    </Typography>
  );
};

export default NotificationExample;
