import { CircularProgress } from '@mui/material';
import { DisplayError, PageHeaderHidden } from 'components';
import { ErrorTypeEnum } from 'components/Error/interface';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useAppSelector } from '../hooks';
import { AuthRouteProps } from '../interface';
import { GetRouteInfo, getRouteInfo } from '../selectors';

const AuthRoute: FC<AuthRouteProps> = (props) => {
  const {
    enheter,
    allowedRoutes,
    userData,
    isLoading,
    isAuthorized,
    currentEnhetId,
  } = useAppSelector<GetRouteInfo>(getRouteInfo);

  const { pathname } = useLocation();
  const { pageProps } = props;
  const hasHelseIdToken = userData.hasId;

  if (isLoading) return <CircularProgress aria-label="laster side-animasjon" />;
  // redirect user to logged-out infopage
  if (pathname === '/logget-ut')
    return hasHelseIdToken ? <Navigate to="/" /> : <>{props.children}</>;

  if (!isAuthorized) {
    return (
      <DisplayError
        errorType={
          userData.isLimitedAccess
            ? ErrorTypeEnum.LimitedAccess
            : userData.isDisabled
            ? ErrorTypeEnum.NoRights
            : ErrorTypeEnum.NoAccess
        }
      />
    );
  }

  if (pathname && currentEnhetId && !isNaN(currentEnhetId) && enheter.length) {
    const allowedLink = allowedRoutes.find((allowedRoute) => {
      const selectedEnhet = enheter.find(
        (enhet) => enhet.id === currentEnhetId
      );
      const { userEnhetAccessToPath, exact, path } = allowedRoute;
      const hasAccess =
        selectedEnhet && selectedEnhet.id
          ? userEnhetAccessToPath.includes(selectedEnhet.id)
          : false;
      if (hasAccess) return exact ? path === pathname : pathname.includes(path);
      else if (pathname === '/sg')
        return true;

      return false;
    });

    const allowUserAccess = !!allowedLink;
    return allowUserAccess ? (
      <>
        <PageHeaderHidden level="h1" description={pageProps?.description} />
        {props.children}
      </>
    ) : (
      <DisplayError errorType={ErrorTypeEnum.NotAllowed} />
    );
  }

  return <DisplayError errorType={ErrorTypeEnum.NotFound} />;
};

export default AuthRoute;
