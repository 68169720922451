import { Grid2, Typography } from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField/useField.types';
import { ScrWrapper } from 'components';
import { useSysProperties } from 'features/sys';
import { DateTime, Interval } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { InnstillingerEnum, InstillingerSys } from 'services/sys/dto';
import { PickerFormat, PickerProps } from 'utils/date';
import { useGetServerTime } from 'utils/hooks';

import { ReestimerProps } from './interface';

const Reestimer: FC<ReestimerProps> = ({ changeDate, isLoading, minDate }) => {
  const { data: innstillinger } =
    useSysProperties<InstillingerSys>('innstillinger');

  const dagerICache =
    innstillinger.find(
      (el) =>
        el?.id ===
        InnstillingerEnum.FØDSELSDATO_ANTALL_DAGER_FREM_I_TID_I_INKLUSJON_KANDIDAT
    )?.verdi || InnstillingerEnum.INGEN;

  const [maxDate, setMaxDate] = useState<DateTime>(DateTime.now());

  const [picker, setPicker] = useState<PickerProps>({
    value: null,
    format: PickerFormat.DATE,
  });

  const [serverTime] = useGetServerTime((time?: DateTime) => {
    initDate(time || DateTime.now());
  });

  const initDate = (time: DateTime) => {
    setPicker((prevState) => ({ ...prevState, value: time }));
    setMaxDate(time.plus({ days: dagerICache || 0 }));
    changeDate(time);
  };

  useEffect(() => {
    if (serverTime) {
      setMaxDate(serverTime.plus({ days: dagerICache || 0 }));
    }
  }, [dagerICache, serverTime]);

  const isInDateRange = (date: DateTime) => {
    return (
      !minDate ||
      Interval.fromDateTimes(minDate.minus({ days: 1 }), maxDate).contains(date)
    );
  };

  const handleDateChange = (
    value: DateTime | null,
    context: FieldChangeHandlerContext<DateValidationError>
  ) => {
    setPicker((prevState) => ({
      ...prevState,
      value: value,
      error: getErrorMessage(context.validationError),
    }));

    if (value && isInDateRange(value)) {
      changeDate(value);
    }
  };

  const handleDatoError = (error: DateValidationError) => {
    setPicker((prevState) => ({ ...prevState, error: getErrorMessage(error) }));
  };

  const getErrorMessage = (error: DateValidationError): string | undefined => {
    switch (error) {
      case 'minDate':
      case 'maxDate':
        return `Dato må være mellom i dag og ${dagerICache} dager frem i tid`;
      case null:
        break;
      default:
        return 'Ugyldig dato';
    }
  };

  return (
    (<ScrWrapper variant="secondary" sx={{ pl: 3, pr: 3 }}>
      <Grid2 container sx={{ p: 2 }} alignItems="center" spacing={2}>
        <Grid2 size={9}>
          <Typography>
            Kandidater fordelt på screeningsentre til og med
          </Typography>
        </Grid2>
        <Grid2 size={3}>
          <DatePicker
            value={picker.value}
            onChange={handleDateChange}
            onError={handleDatoError}
            format={picker.format.inputFormat}
            disabled={isLoading}
            maxDate={maxDate}
            minDate={minDate}
            label="Til og med"
            slotProps={{
              textField: {
                size: 'small',
                error: !!picker.error,
                helperText: picker.error,
              },
            }}
          />
        </Grid2>
      </Grid2>
    </ScrWrapper>)
  );
};

export default Reestimer;
