import { TextField, TextFieldProps, useTheme } from '@mui/material';

const ScrTextarea = (props: TextFieldProps) => {
  const theme = useTheme();

  return (
    <TextField
      {...props}
      slotProps={{
        ...props.slotProps,
      }}
      multiline
      spellCheck={false}
      sx={{
        width: '100%',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        borderBottom: `1px solid ${theme.palette.scr.grey600}`,
        '&:focus-visible': {
          outline: 'none',
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '&:hover': {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
      }}></TextField>
  );
};

export default ScrTextarea;
