import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ScrWrapper } from 'components';
import { addNotification } from 'features/notification/notificationSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultDialogWidth } from 'utils/styles';

import BekreftelseAlert from './BekreftelseAlert';
import { BekreftelseDialogProps } from './interface';

const BekreftelseDialog: React.FC<BekreftelseDialogProps> = ({
  open,
  close,
  bekreftText,
  bekreft,
  children,
}) => {
  const dispatch = useDispatch();

  const [showPending, setShowPending] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const handleClose = () => {
    setShowPending(false);
    setDisplayError(false);
    close();
  };
  const onClick = () => {
    setDisplayError(false);
    setShowPending(true);

    bekreft()
      .then(() => {
        dispatch(
          addNotification({
            message: {
              title: 'Sendt',
              text: `Utsendingsoppdraget ble lagret`,
            },
            variant: 'success',
          })
        );
        handleClose();
      })
      .catch(() => setDisplayError(true))
      .finally(() => setShowPending(false));
  };

  return (
    <Dialog open={open} slotProps={{ paper: { sx: defaultDialogWidth } }}>
      {displayError && (
        <DialogContent>
          <BekreftelseAlert />
        </DialogContent>
      )}
      <DialogTitle>Er du sikker?</DialogTitle>
      <DialogContent>
        <ScrWrapper variant="dialog">{children}</ScrWrapper>
      </DialogContent>
      <DialogActions>
        <Button disabled={showPending} onClick={handleClose}>
          Avbryt
        </Button>
        <Button
          variant="contained"
          onClick={onClick}
          loading={showPending}>
          {bekreftText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BekreftelseDialog;
