import { Grid2 } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { FC } from 'react';

import DataRow from '../details/components/DeltakerCommunicationLog/DataRow';
import { DeltakerInformationProps } from './interface';
import { deltakerInformasjonSelector } from './selectors';
import DeltakerInformationAdresser from './DeltakerInformationAdresser';

const DeltakerInformation: FC<DeltakerInformationProps> = ({
  hideFødselsnummer,
  hideDeltakerAdresse,
}) => {
  const { fødselsnummer, navn, adresser } = useAppSelector(
    deltakerInformasjonSelector
  );
  return (
    <Grid2 container>
      {!hideFødselsnummer && (
        <DataRow left={4} right={8} title="Fnr" value={fødselsnummer} />
      )}
      <DataRow left={4} right={8} title="Navn" value={navn} />

      {adresser && adresser.length === 0 && !hideDeltakerAdresse && (
        <DataRow
          left={4}
          right={8}
          title="Adresse"
          value="Ingen adresse registrert."
        />
      )}
      {adresser && adresser.length > 0 && !hideDeltakerAdresse && (
        <DeltakerInformationAdresser adresser={adresser} />
      )}
    </Grid2>
  );
};

export default DeltakerInformation;
