import {
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ScreeningmetodefordelingDto } from 'services/inklusjon/dto';
import inklusjonService from 'services/inklusjon/inklusjonService';
import { useDebounce, useSendData } from 'utils/hooks';

import { KorrigertAntallInklusjonCell } from './';
import { AntallInklusjonRowProps } from './interface';
import { getStrikeThrough } from './utils';

const AntallInklusjonRow: React.FC<AntallInklusjonRowProps> = ({
  antallsbegrensningActive,
  row,
  dataFunctions: { setKorrigertAntall, setSelected },
  inklusjonsProps,
  setDisableButton,
}) => {
  const [korrigertAntallInput, setKorrigertAntallInput] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const { data, send, status } = useSendData<ScreeningmetodefordelingDto>();
  const debouncedAntall = useDebounce<number | undefined>(
    korrigertAntallInput ? Number(korrigertAntallInput) : undefined,
    250
  );

  useEffect(() => {
    row.korrigertAntall !== undefined &&
      setKorrigertAntallInput(row.korrigertAntall.toString());
  }, [row.korrigertAntall]);

  useEffect(() => {
    if (setKorrigertAntall) {
      if (setDisableButton && korrigertAntallInput.match(/^0+$/)) {
        setKorrigertAntall(row.id, 0, 0, 0);
        setDisableButton(false);
      } else if (!status.errorMessage && !status.loading) {
        setKorrigertAntall(
          row.id,
          korrigertAntallInput ? Number(korrigertAntallInput) : undefined,
          data?.korrigertAntallIfobt,
          data?.korrigertAntallKoloskopi
        );
      } else if (status.errorMessage) {
        setError(status.errorMessage);
      }
    }
    if (row.harRelevantIRuteDatoMenAntallGjenværendeKandidaterTilIRuteMangler) {
      setWarning(
        'I rute dato er satt, men antall gjenværende kandidater mangler.'
      );
    } else if (
      row.medNåværendeMaksAntallKoloskopierPerPeriodeVilManIkkeVæreIRuteTilAngittIRuteDato
    ) {
      setWarning(
        'Med nåværende maks antall koloskopier vil man ikke være i rute til angitt i rute dato.'
      );
    }
  }, [
    data,
    korrigertAntallInput,
    row.antall,
    row.id,
    row.harRelevantIRuteDatoMenAntallGjenværendeKandidaterTilIRuteMangler,
    row.medNåværendeMaksAntallKoloskopierPerPeriodeVilManIkkeVæreIRuteTilAngittIRuteDato,
    setKorrigertAntall,
    setDisableButton,
    status.errorMessage,
    status.loading,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (antallsbegrensningActive && debouncedAntall && debouncedAntall !== 0) {
      send(() =>
        inklusjonService.getScreeningmetodeFordeling(
          inklusjonsProps.tilOgMedDato,
          debouncedAntall,
          row.id,
          abortController.signal
        )
      ).then(() => setDisableButton && setDisableButton(false));
    } else if (debouncedAntall !== 0) {
      setDisableButton && setDisableButton(false);
      setError('');
    }
    return () => {
      abortController.abort();
    };
  }, [
    debouncedAntall,
    row.id,
    send,
    inklusjonsProps.tilOgMedDato,
    antallsbegrensningActive,
    setDisableButton,
  ]);

  const handleAntallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');

    setDisableButton && setDisableButton(true);
    const { value } = event.target;
    if (setKorrigertAntall) {
      if (!value) {
        setKorrigertAntallInput('');
      } else if (Number.isInteger(Number(value))) {
        setKorrigertAntallInput(value);
      }
    }
  };
  const toggleSelectedSenter = () => {
    setSelected(row.id, !row.selected);
  };
  return (<>
    <TableRow>
      <TableCell>
        <Checkbox checked={row.selected} onClick={toggleSelectedSenter} />
      </TableCell>
      <TableCell>{row.text}</TableCell>
      <TableCell align="center" sx={{ minWidth: '200px' }}>
        <KorrigertAntallInklusjonCell
          isKorrigert={
            antallsbegrensningActive &&
            row.selected &&
            Number.isInteger(Number(row.korrigertAntall))
          }
          antallIFobt={row.antallIfobt}
          antallKoloskopi={row.antallKoloskopi}
          korrigertIFobt={row.korrigertAntallIfobt}
          korrigertKoloskopi={row.korrigertAntallKoloskopi}
          selected={row.selected}
        />
      </TableCell>
      <TableCell
        align="center"
        sx={getStrikeThrough(
          antallsbegrensningActive &&
            row.selected &&
            Number.isInteger(row.korrigertAntall)
        )}>
        {row.selected ? row.antall : '-'}
      </TableCell>
      {antallsbegrensningActive && (
        <TableCell align="center" sx={{ bgcolor: 'scr.grey50' }}>
          {row.selected && (
            <TextField
              name={row.id.toString()}
              value={korrigertAntallInput}
              variant="outlined"
              size="small"
              onChange={handleAntallChange}
              error={(row.korrigertAntall ?? 0) > row.antall}
              sx={{
                width: 80,
              }}
              slotProps={{
                input: {
                  sx: {
                    ...(error && {
                      border: (theme: Theme) =>
                        `1px solid ${theme.palette.scr.red700}`,
                    }),
                  },
                },

                htmlInput: {
                  maxLength: 9,
                  pattern: /\D/,
                }
              }} />
          )}
        </TableCell>
      )}
    </TableRow>
    {error !== '' && row.selected && (
      <TableRow
        sx={{
          bgcolor: 'scr.red100',
        }}>
        <TableCell colSpan={5} align="right">
          {
            <Typography gutterBottom variant="caption">
              {error}
            </Typography>
          }
        </TableCell>
      </TableRow>
    )}
    {warning !== '' && row.selected && (
      <TableRow
        sx={{
          bgcolor: 'scr.yellow100',
        }}>
        <TableCell colSpan={5} align="right">
          {
            <Typography gutterBottom variant="caption">
              {warning}
            </Typography>
          }
        </TableCell>
      </TableRow>
    )}
  </>);
};

export default AntallInklusjonRow;
