import { Box } from '@mui/material';
import { PageHeaderHidden } from 'components';

import ScrWapperProps from './interface';

const ScrWrapper: React.FC<ScrWapperProps> = ({
  id,
  variant = 'primary',
  error,
  children,
  component,
  hiddenHeader,
  ariaLabel,
  sx,
}: ScrWapperProps) => {
  const sxWithDefaults =
    variant === 'dialog' ? { mb: 1, py: 1, px: 2, ...sx } : sx;

  return (
    <Box
      id={id}
      component={component || 'div'}
      aria-label={ariaLabel}
      sx={{
        bgcolor: variant === 'primary' ? 'common.white' : 'scr.grey100',
        borderWidth: error ? '2px' : '1px',
        borderStyle: 'solid',
        borderColor: error ? 'error.main' : 'scr.grey300',
        borderRadius: variant === 'dialog' ? '4px' : '',
        width: '100%',
        ...sxWithDefaults,
      }}>
      {hiddenHeader && <PageHeaderHidden {...hiddenHeader} />}
      {children}
    </Box>
  );
};

export default ScrWrapper;
