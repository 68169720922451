import { Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import DraggablePaper from 'components/Draggable/DraggablePaper';
import { demaskertSelector } from 'features/deltaker/details/components/selectors';
import { displayNotificationAndLogError } from 'features/errorHandling/errorHandlingUtils';
import { FC, ReactElement, useEffect, useState } from 'react';
import { defaultDialogWidth } from 'utils/styles';

import { SpecialHenvendelsetypeEnum } from '../interface';
import FritekstBrevDialogForm from './fritekstBrev';
import { BaseDialogProps } from './interface';
import ManuellHenvisningDialogForm from './ManuellHenvisning/ManuellHenvisningDialogForm';
import NyHenvendelseDialogForm from './NyHenvendelse/NyHenvendelseDialogForm';
import OppfølgingspunktContainer from './Oppfølgingspunkt';
import RedigerHenvendelseDialogForm from './RedigerHenvendelse/RedigerHenvendelseDialogForm';
import { getSendingHenvendelseStatus } from './selectors';
import { ShowHenvendelse } from './show';
import NyttNotatDialog from "../../DeltakerCommunicationLog/Notat/NyttNotatDialog";

const BaseDialog: FC<BaseDialogProps> = ({
  open,
  henvendelseType,
  henvendelseTypeId,
  log,
  close,
}) => {
  const dispatch = useAppDispatch();
  const { adresser } = useAppSelector(demaskertSelector);
  const [error, setError] = useState<Error>();
  const [effektivPostAdresseIdKryptert, setEffektivPostAdresseIdKryptert] =
    useState<string>();
  const sendStatus = useAppSelector(getSendingHenvendelseStatus);
  useEffect(() => {
    if (error) {
      displayNotificationAndLogError(dispatch, error, 'warning');

      setError(undefined);
      close();
    }
  }, [close, dispatch, error]);

  useEffect(() => {
    if (sendStatus.success) {
      close();
    }
  }, [sendStatus.success, close]);

  useEffect(() => {
    const effektivPostAdresseIdKryptert = adresser
      ?.filter((adresse) => adresse.effektivPostAdresse)
      .map((adresse) => adresse.idKryptert)[0];

    effektivPostAdresseIdKryptert &&
      setEffektivPostAdresseIdKryptert(effektivPostAdresseIdKryptert);
  }, [adresser, effektivPostAdresseIdKryptert]);

  const henvendelseTypeIdDependentForm = () => {
    if (!henvendelseTypeId) return;

    if (henvendelseType === 'NY') {
      return (
        <NyHenvendelseDialogForm
          henvendelseTypeId={henvendelseTypeId}
          deltakerAdresser={adresser}
          effektivPostAdresseIdKryptert={effektivPostAdresseIdKryptert}
          close={close}
        />
      );
    }
    if (henvendelseType === 'OPPDATER' && log) {
      return (
        <RedigerHenvendelseDialogForm
          henvendelseTypeId={henvendelseTypeId}
          log={log}
          close={close}
        />
      );
    }
    if (henvendelseType === 'SHOW' && log) {
      return (
        <ShowHenvendelse
          henvendelseTypeId={henvendelseTypeId}
          log={log}
          close={close}
        />
      );
    }
    if (henvendelseType === SpecialHenvendelsetypeEnum.NY_FRITEKSTBREV) {
      return (
        <FritekstBrevDialogForm
          henvendelseTypeId={henvendelseTypeId!}
          deltakerAdresser={adresser}
          effektivPostAdresseIdKryptert={effektivPostAdresseIdKryptert}
          close={close}
        />
      );
    }
  };

  const renderForm = () => {
    let form: ReactElement | undefined = henvendelseTypeIdDependentForm();

    if (
      !form &&
      henvendelseType === SpecialHenvendelsetypeEnum.OPPFØLGINGSPUNKT
    )
      form = (
        <OppfølgingspunktContainer
          close={close}
          oppfølgingsFormType="INDIVIDUAL"
        />
      );

    if (
      !form &&
      henvendelseType === SpecialHenvendelsetypeEnum.MANUELL_HENVISNING
    )
      form = <ManuellHenvisningDialogForm close={close} />;

    if (
      !form &&
      henvendelseType === SpecialHenvendelsetypeEnum.NOTAT
    )
      form = <NyttNotatDialog close={close} />;

    if (form) return form;
    else
      setError(
        new Error(
          `Ukjent henvendelseType ${henvendelseType} for henvendelseTypeId ${henvendelseTypeId}`
        )
      );
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="henvendelse-dialog-title"
      PaperComponent={
        henvendelseType === 'NY'
          ? (props) => (
              <DraggablePaper
                paperProps={props}
                handle="#henvendelse-dialog-title"
                cancel='[class*="MuiDialogContent-root"]'
              />
            )
          : undefined
      }
      slotProps={{ paper: { sx: defaultDialogWidth } }}>
      {open && !error && renderForm()}
    </Dialog>
  );
};

export default BaseDialog;
