import { FormControlLabel, Grid2, Switch, Typography } from '@mui/material';
import React from 'react';

import { AntallsbegrensningProps } from './interface';

const Antallsbegrensning: React.FC<
  AntallsbegrensningProps & { invalid: boolean }
> = ({ active, toggle, text, invalid }) => {
  return (<>
    <Grid2 container justifyContent="flex-end" size={12}>
      <FormControlLabel
        label="Aktiver antallsbegrensning"
        labelPlacement="start"
        control={<Switch checked={active} onClick={toggle} />}
      />
    </Grid2>
    <Grid2 container justifyContent="flex-end" size={12}>
      <Typography
        variant="caption"
        sx={{
          color: invalid && active ? 'error.main' : 'primary.main',
          fontStyle: 'italic',
        }}>
        {text}
      </Typography>
    </Grid2>
  </>);
};

export default Antallsbegrensning;
