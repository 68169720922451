import { addNotification } from 'features/notification/notificationSlice';
import { useDispatch } from 'react-redux';

async function copyTextToClipboard(text: string): Promise<void> {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      throw new Error('Kunne ikke kopiere text');
    }
  } else {
    throw new Error('Clipboard API er ikke tilgængelig');
  }
}

const useCopyTextToClipboard = () => {
  const dispatch = useDispatch();

  const copyText = async (text: string) => {
    try {
      await copyTextToClipboard(text);
      dispatch(
        addNotification({
          message: { text: 'Tekst kopiert til utklippstavlen' },
          variant: 'info',
        })
      );
    } catch (e) {
      addNotification({
        message: { text: (e as Error).message },
        variant: 'error',
      });
    }
  };
  return [copyText];
};

export default useCopyTextToClipboard;
