import { Box, Grid2, Typography } from '@mui/material';
import { ScrIcon } from 'components';
import { useSysProperties } from 'features/sys';
import { FC } from 'react';
import { DeltakerInformationAdresserProps } from './interface';

const DeltakerInformationAdresser: FC<DeltakerInformationAdresserProps> = ({
  adresser,
}) => {
  const { mapBeskrivelse } = useSysProperties('adresseTyper');
  return (<>
    {adresser?.map(({ adresse, adresseType, effektivPostAdresse }) => (
      <>
        <Grid2 size={4}>
          <Typography variant="subtitle2">
            {mapBeskrivelse(adresseType)}:
            {effektivPostAdresse && adresser?.length > 1 && (
              <Box component={'span'} sx={{ pl: 1 }}>
                <ScrIcon iconId={74} size="small" />
              </Box>
            )}
          </Typography>
        </Grid2>
        <Grid2 size={8}>
          <Typography variant="body2">
            {adresse}
            {effektivPostAdresse && adresser?.length > 1 && (
              <Typography
                sx={{
                  display: 'inline',
                  fontStyle: 'italic',
                  fontSize: 13,
                  color: (theme) => theme.palette.scr.lightBlue900,
                }}>
                &nbsp;(Effektiv postadresse)
              </Typography>
            )}
          </Typography>
        </Grid2>
      </>
    ))}
  </>);
};

export default DeltakerInformationAdresser;
