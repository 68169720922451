import { Box } from '@mui/material';
import { SrcPageHeader } from 'components';
import { FC } from 'react';
import { screenReaderOnly } from 'utils/styles';

import { SrcPageProps } from './interface';

const ScrPage: FC<SrcPageProps> = ({
  headline,
  ingress,
  hiddenHeader,
  children,
  iconId,
}) => {
  return (
    <>
      <title>{headline}</title>
      <Box sx={{ ...(hiddenHeader && screenReaderOnly) }}>
        <SrcPageHeader h1Text={headline} h2Text={ingress} iconId={iconId} />
      </Box>
      {children}
    </>
  );
};
export default ScrPage;
