import {
  Box,
  Button,
  Checkbox,
  Grid2,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ScrIcon } from 'components';
import { useEffect, useState } from 'react';

import {
  createFilterMenu,
  filterRows,
  forceSetAllCategoryOptions,
  isAllCategoryOptionsSelected,
  isSomeCategoryOptionsSelected,
  selectCategoryOption,
  toggleSelectAllCategoryOptions,
} from './functions';
import {
  CategoryOption,
  FilterMenuCategory,
  FilterMenuProps,
} from './interfaces';

const FilterMenu: React.FC<FilterMenuProps> = ({
  rawDataRows,
  updateFilteredRows,
  categoryProps,
  buttons,
  closeFilter,
}: FilterMenuProps) => {
  const [filterMenuCategories, setFilterMenuCategories] = useState<
    FilterMenuCategory[]
  >([]);

  useEffect(() => {
    if (filterMenuCategories.length === 0) {
      const filterMenu = createFilterMenu(rawDataRows, categoryProps);
      setFilterMenuCategories(filterMenu);
    }
  }, [filterMenuCategories, rawDataRows, categoryProps]);

  const updateSelectedOptions = (categoryKey: string, optionValue?: string) => {
    const newFilter = optionValue
      ? selectCategoryOption(filterMenuCategories, categoryKey, optionValue)
      : toggleSelectAllCategoryOptions(filterMenuCategories, categoryKey);
    setFilterMenuCategories(newFilter);
    updateFilteredRows(filterRows(rawDataRows, newFilter));
  };

  const resetFilterMenuSelection = () => {
    const newFilter = forceSetAllCategoryOptions(filterMenuCategories, false);
    updateFilteredRows(filterRows(rawDataRows, newFilter));
  };

  const renderCategoryOptionsList = (
    categoryKey: string,
    categoryOptions: CategoryOption[],
    parentListItemKey: string
  ) => {
    const categoryOptionsListItems = categoryOptions.map(
      (categoryOption, index) => {
        const key = `${parentListItemKey}-option-${index}`;

        return (
          <ListItemButton
            key={key}
            role={undefined}
            onClick={() =>
              updateSelectedOptions(categoryKey, categoryOption.key)
            }
            dense={true}>
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <Checkbox
                edge="start"
                checked={categoryOption.isSelected}
                size="small"
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': key }}
              />
            </ListItemIcon>
            <ListItemText id={key} primary={categoryOption.label} />
          </ListItemButton>
        );
      }
    );

    return <List>{categoryOptionsListItems}</List>;
  };

  const categoryListItems = filterMenuCategories.map((category) => {
    const {
      key: categoryKey,
      label: categoryLabel,
      options: categoryOptions,
    } = category;

    const categoryShouldBeChecked =
      isAllCategoryOptionsSelected(categoryOptions);

    const showIndeterminate =
      !categoryShouldBeChecked &&
      isSomeCategoryOptionsSelected(categoryOptions);

    const key = `filter-${categoryKey}`;
    return (
      (<Box key={key}>
        {categoryLabel && (
          <ListItemButton
            divider
            onClick={() => updateSelectedOptions(categoryKey)}>
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <Checkbox
                edge="start"
                disableRipple
                checked={categoryShouldBeChecked}
                size="small"
                indeterminate={showIndeterminate}
                inputProps={{ 'aria-labelledby': key }}
              />
            </ListItemIcon>
            <ListItemText
              id={key}
              primary={categoryLabel}
              slotProps={{
                primary: {
                  variant: 'subtitle2',
                  color: 'primary',
                }
              }}
            />
          </ListItemButton>
        )}
        {renderCategoryOptionsList(categoryKey, categoryOptions, key)}
      </Box>)
    );
  });

  return (
    <>
      {buttons && (
        <Grid2 container justifyContent="flex-end">
          <Button
            startIcon={<ScrIcon iconId={68} size="small" />}
            onClick={closeFilter}
            aria-label="Lukk filter">
            Filter
          </Button>
        </Grid2>
      )}
      <List>{categoryListItems}</List>
      {buttons && (
        <Button
          size="large"
          onClick={resetFilterMenuSelection}
          aria-label="nullstill filter">
          Nullstill filter
        </Button>
      )}
    </>
  );
};

export default FilterMenu;
